import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Goods } from 'src/app/model/goods/goods';
import { ExtPageRepositoryService } from '../abstract-ext-repository.service';

@Injectable({
  providedIn: 'root',
})
export class GoodsRcmdService extends ExtPageRepositoryService<Goods> {
  baseUri = `api/goods`;

  extFixedParams = {
    activeFl: true,
    attrJson: 'RCMD',
    sort: 'sortNumber,asc',
  };

  constructor(protected http: HttpClient) {
    super(http);
  }
}
