import { registerLocaleData } from '@angular/common';
import {
  HttpClient,
  HttpClientJsonpModule,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { COMPOSITION_BUFFER_MODE } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import {
  MatDialogConfig,
  MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';
import { AuthInterceptor, LoggingInterceptor, PW_STORAGE_PREFIX } from 'pw-lib';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './auth/auth.service';
import { AddressFinderModule } from './components/address-finder/address-finder.module';
import { CartModule } from './components/cart/cart.module';
import { SharedModule } from './components/shared.module';
import { maskConfig } from './core/mask-config';
import {
  PwUpdateOption,
  PW_UPDATE_OPTION,
} from './core/services/app-update.service';
import { PipesModule } from './pipes/pipes.module';
import { DialogService } from './components/dialog/dialog.service';
import { ErrorInterceptor } from './interceptors/error.interceptor';

registerLocaleData(localeJa);
registerLocaleData(localeKo);

const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

const pwUpdateOption: PwUpdateOption = {
  messageTranslateKey: 'MSG.askUpdate',
  actionTranslateKey: 'update',
};

const matDialogDefaultOptions: MatDialogConfig = {
  autoFocus: false,
  minWidth: '240px',
  panelClass: 'dialog-panel',
  hasBackdrop: true,
  backdropClass: 'dialog-backdrop',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgxMaskModule.forRoot(maskConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    PipesModule,
    SharedModule,
    AddressFinderModule,
    MatNativeDateModule,
    CartModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: (authService: AuthService) => {
        return new AuthInterceptor(
          authService,
          `${environment.apiServerUrl}/api`
        );
      },
      deps: [AuthService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: () => {
        // return new LoggingInterceptor(environment.production);
        return new LoggingInterceptor(false);
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useFactory: (dialogService: DialogService) => {
        return new ErrorInterceptor(dialogService);
      },
      deps: [DialogService],
    },
    { provide: COMPOSITION_BUFFER_MODE, useValue: false },
    { provide: PW_STORAGE_PREFIX, useValue: 'joyfood' },
    { provide: PW_UPDATE_OPTION, useValue: pwUpdateOption },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: matDialogDefaultOptions },
    { provide: LOCALE_ID, useValue: 'ja-JP' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'JPY' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
