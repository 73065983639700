import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { map, tap } from 'rxjs';
import { Goods } from 'src/app/model/goods/goods';
import { IGoodsOptMaster } from 'src/app/repository/goods-opt-master/goods-opt-master.model';
import { IGoodsOpt } from 'src/app/repository/goods-opt/goods-opt.model';
import { GoodsService } from 'src/app/repository/goods/goods.service';
import { CartGoods, CartService } from 'src/app/services/cart.service';
import { DialogService } from '../dialog/dialog.service';

@Component({
  selector: 'app-goods-detail',
  templateUrl: './goods-detail.component.html',
  styleUrls: ['./goods-detail.component.scss'],
})
export class GoodsDetailComponent implements OnInit {
  goodsId: any;

  goods: Goods;

  goodsCnt = 1;

  goodsOptAmt = 0;

  goodsOptFormGroup: FormGroup;

  goodsOptMap: Map<number, IGoodsOpt> = new Map();

  goodsOptMasterMap: Map<number, IGoodsOptMaster> = new Map();

  type: 'delivery' | 'takeout';

  private isCartClicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    matDialogData: { id: number; type: 'delivery' | 'takeout' },
    private matDialogRef: MatDialogRef<GoodsDetailComponent>,
    private dialogService: DialogService,
    private cartService: CartService,
    private goodsService: GoodsService
  ) {
    this.goodsId = matDialogData.id;
    this.type = matDialogData.type;
  }

  ngOnInit(): void {
    this.initGoods();
  }

  onFormGroupInit(formGroup: FormGroup): void {
    this.goodsOptFormGroup = formGroup;

    this.goodsOptFormGroup.valueChanges
      .pipe(
        map((changes: { [id: number]: number[] }) => {
          // 배열 평탄화
          return [].concat(...Object.values(changes));
        }),
        tap((goodsOptMasterIdList: number[]) => {
          let goodsOptAmt = 0;

          goodsOptMasterIdList.forEach((goodsOptMasterId) => {
            goodsOptAmt +=
              this.goodsOptMasterMap.get(goodsOptMasterId).goodsOptAmt;
          });

          this.goodsOptAmt = goodsOptAmt;
        })
      )
      .subscribe();
  }

  onBackClick(): void {
    this.matDialogRef.close();
  }

  onCntChange(cnt: number): void {
    this.goodsCnt = this.goodsCnt + cnt || 1;
  }

  onCartClick(): void {
    if (this.isCartClicked) {
      return;
    }

    if (this.goodsOptFormGroup.invalid) {
      this.goodsOptFormGroup.markAllAsTouched();
      this.dialogService.alert('MSG.checkGoodsOpt', 'alert').subscribe();
      return;
    }

    const goodsOptList = [];

    Object.keys(this.goodsOptFormGroup.value).forEach((goodsOptId: string) => {
      if (this.goodsOptFormGroup.value[goodsOptId].length < 1) {
        return;
      }

      const goodsOptMasterList = [];

      this.goodsOptFormGroup.value[goodsOptId].forEach(
        (goodsOptMasterId: number) => {
          goodsOptMasterList.push(this.goodsOptMasterMap.get(goodsOptMasterId));
        }
      );

      goodsOptList.push({
        ...this.goodsOptMap.get(Number(goodsOptId)),
        goodsOptMasterList,
      });
    });

    const cartData: CartGoods = {
      goodsId: this.goods.id,
      goodsAmt: this.goods.goodsAmt,
      goodsCnt: this.goodsCnt,
      goodsNm: this.goods.goodsNm,
      goodsOptList,
      goodsAttrJson: this.goods.attrJson,
    };

    this.isCartClicked = true;
    this.cartService.addData([cartData], this.type);
    this.matDialogRef.close();
  }

  /**
   * 상품 정보
   */
  private initGoods(): void {
    this.goodsService
      .findItem(this.goodsId)
      .pipe(
        tap((goods: Goods) => {
          this.goods = goods;

          goods.goodsOptList.forEach((goodsOpt) => {
            const copied = { ...goodsOpt };
            delete copied.goodsOptMasterList;
            this.goodsOptMap.set(goodsOpt.id, copied);

            goodsOpt.goodsOptMasterList.forEach((goodsOptMaster) => {
              this.goodsOptMasterMap.set(goodsOptMaster.id, goodsOptMaster);
            });
          });
        })
      )
      .subscribe();
  }
}
